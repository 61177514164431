<script>
import {mdiCalendarClock} from "@mdi/js";

export default {
  name: "LabelDeadline",
  props: {
    deadline: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
    },
  },
  computed: {
    getDeadline() {
      const rawDate = this.deadline
      const date = rawDate ? new Date(rawDate) : new Date()
      return date.toLocaleDateString("ru-RU", {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
      })
    }
  },
  methods: {
    mdiCalendarClock() {
      return mdiCalendarClock
    }
  }
}
</script>

<template>
  <v-chip
    color="warning"
    :small="small"
  >
    <v-icon class="mr-2">
      {{ mdiCalendarClock() }}
    </v-icon>
    <template v-if="!small">
      Заполнить до: &nbsp;
    </template>
    <strong>{{ getDeadline }}</strong>
  </v-chip>
</template>